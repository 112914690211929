<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">Viewed Plots</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Plots</a></li>
          <li class="breadcrumb-item active" aria-current="page">Viewed</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Filter</h4>
            <ValidationObserver v-slot="{}" ref="form">
              <form class="forms-sample">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="date_range" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="date_range" horizontal label="Date Range">
                        <date-picker v-model="date_range" style="width:100%;" @change="filterPlots" type="date" value-type="format" format="YYYY-MM-DD" range></date-picker>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="date_range" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="user" horizontal label="Search User">
                        <vue-autosuggest v-model="term" :suggestions="users" :sectionConfigs="sectionConfigs" :inputProps="inputProps" :renderSuggestion="renderSuggestion" :get-suggestion-value="getSuggestionValue">
                        </vue-autosuggest>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider action="#">
                      <b-form-group class="form-group d-lg-flex" label="Per Page">
                        <b-form-input type="number" class="form-control" @change="paginateChange"
                                      placeholder="perPage" v-model="paginate"></b-form-input>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h4 class="card-title">Plots</h4>
              <download-excel
                class="btn btn-success"
                :data="plots"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="most_viewed_plots.xlsx">
                Download Plots
              </download-excel>
            </div>
            <b-table :items="plots" :busy="isBusy" id="table-list" responsive :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(coords)="data">
                <span>{{ (data.item.plot.coords == null) ? "N/A" : data.item.plot.coords }}</span>
              </template>
              <template v-slot:cell(id)="data">
                <span>{{ (data.item.plot.id == null) ? "N/A" : data.item.plot.id }}</span>
              </template>
              <template v-slot:cell(planNo)="data">
                <span>{{ (data.item.plan.planNo == null) ? "N/A" : data.item.plan.planNo }}</span>
              </template>
              <template v-slot:cell(plot)="data">
                <span>{{ (data.item.plot.plot == null) ? "N/A" : data.item.plot.plot }}</span>
              </template>
              <template v-slot:cell(meter_sq)="data">
                <span>{{ (data.item.plot.meter_sq == null) ? "N/A" : data.item.plot.meter_sq }}</span>
              </template>
              <template v-slot:cell(neighborhood)="data">
                <span>{{ (data.item.plot.neighborhood == null) ? "N/A" : data.item.plot.neighborhood }}</span>
              </template>
              <template v-slot:cell(classification)="data">
                <span>{{ (data.item.plot.classification == null) ? "N/A" : data.item.plot.classification }}</span>
              </template>
              <template v-slot:cell(user)="data">
                <span>
                  <router-link :to="{name: 'customer-view', params: {id: data.item.user ? data.item.user.id : null}}"
                               target="_blank">{{data.item.user ? `${data.item.user.first_name} ${data.item.user.last_name}` : 'N/A'}}
                  </router-link>
                </span>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <!--
              <template v-slot:cell(action)="data">
                <b-badge pill variant="outline-info">
                  <router-link :to="{ name: 'plot-view', params: {id: data.item.plot.id} }">View</router-link>
                </b-badge>
                <b-badge pill variant="outline-info">
                  <router-link :to="{ name: 'plot-edit', params: {id: data.item.plot.id} }">Edit</router-link>
                </b-badge>
              </template> -->
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
</template>
<script>
/* eslint-disable no-debugger, no-console */
import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import { ValidationObserver } from 'vee-validate'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import helper from '../../util/helper.js'
import _ from 'lodash'
import { VueAutosuggest } from 'vue-autosuggest';

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      selected: "",
      sortable: true,
      currentPage: 1,
      perPage: 0,
      paginate: 10,
      isBusy: true,
      date_range: "",
      start_date: "",
      term: "",
      user_id: "",
      end_date: "",
      sectionConfigs: {
        default: {
          limit: 6,
          label: "Customers",
          onSelected: selected => {
            this.user_id = selected.item.value
          }
       }
      },
      inputProps: {
        id: "autosuggest__input",
        placeholder: "Search for user",
        class: "form-control",
        name: "user"
      },
      fields: [
        { key: 'id', sortable: true },
        { key: 'planNo', sortable: true },
        { key: 'plot', sortable: true },
        { key: 'meter_sq', sortable: true},
        { key: 'classification', sortable: true},
        { key: 'neighborhood', sortable: true},
        { key: 'coords', sortable: false},
        { key: 'user', sortable: false},
        { key: 'created_at', sortable: false},
        // { key: 'action', sortable: false}
      ],
      json_fields: {
        'ID': 'plot.id',
        "Plot Number": "plot.plot",
        "Plan Number": "plan.planNo",
        'Classification': 'plot.classification',
        'Area Size': 'plot.meter_sq',
      }
    };
  },
  components: {
    ValidationObserver,
    DatePicker,
    VueAutosuggest
  },
  computed: {
    rows() {
      return this.$store.state.plot.viewed_meta.total
    },
    paginationPageSize() {
      return this.$store.state.plot.viewed_meta.paginate
    },
    plots() {
      return this.$store.state.plot.viewed
    },
    users() {
      return this.$store.getters["customer/userSuggestions"]
    }
  },
  watch: {
    currentPage() {
      if(this.currentPage != this.$store.state.plot.viewed_meta.current_page)
        this.fetchPlots(this.currentPage)
    },
    date_range(newVal) {
      if(newVal[0] != null && newVal[1] != null) {
        this.start_date = newVal[0]
        this.end_date = newVal[1]
      }
      else {
        this.start_date = ""
        this.end_date = ""
      }
    },
    term(newVal) {
      if (newVal == "") {
        this.user_id = ""
      }
      this.fetchCustomers()
    },
    user_id() {
      this.fetchPlots(this.currentPage)
    }
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    filterPlots: _.debounce(function() {
      this.fetchPlots(this.currentPage)
    }, 2000),
    fetchCustomers: _.debounce(function() {
      let payload = {
        meta: {
          term: this.term
        }
      }
       this.$store.dispatch("customer/searchCustomers", payload)
    }, 1000),
    fetchPlots(current_page = 1) {
      this.isBusy = true

      const payload = {
        meta: {
          page: current_page,
          paginate: this.paginate,
          start_date: this.start_date,
          end_date: this.end_date,
          user_id: this.user_id
        },
      }
      for (const [key, value] of Object.entries(payload.meta)) {
        if (value == "")
          delete payload.meta[key]
      }

      this.$store.dispatch("plot/viewedPlots", payload)
      .then(() => {
        this.isBusy = false
      })
      .catch(() => {
        this.isBusy = false
      })
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.text
    },
    renderSuggestion(suggestion) {
      return suggestion.item.text
    },
    paginateChange: _.debounce(function() {
      if (this.paginate > 0 && this.paginate != "")
        this.fetchPlots()
    }, 500),
  },
  created() {
    this.fetchPlots()
 },
}
</script>
<style>
#autosuggest__input {
  outline: none;
  position: relative;
  display: block;
  /*border: 1px solid #616161;
  padding: 10px; */
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  max-height: 400px;
  overflow-y: scroll;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 15px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results-before {
  color: gray;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
  .autosuggest__results-item.autosuggest__results-item--highlighted {
  background-color: #f6f6f6;
}
</style>
